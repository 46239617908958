import * as React from "react"
import {graphql} from "gatsby";
import Layout from '../components/Layout/Layout';
import WideImageSection from '../components/WideImageSection/WideImageSection';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const Covid = ({data}) => {
  const articles = data.allStrapiArticle.edges;
  
  return (
    <Layout pageTitle='COVID 19'>
        {articles.map(article => (
          <div>
            <WideImageSection image={article.node.HeaderImage ? article.node.HeaderImage.localFile : null} heading={article.HeaderText}/>
            <CenteredSection>
                <h2>{article.node.Title}</h2>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={article.node.Content}/>
            </CenteredSection>
          </div>
        ))}
    </Layout>
  )
}
export default Covid;

export const pageQuery=graphql`
  query CovidQuery {
    allStrapiArticle(filter: {Page: {eq: "covid"}} sort: {order: ASC, fields: Index}) {
      edges {
        node {
          HeaderText
          HeaderImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          Title
          Content
        }
      }
    }
    allStrapiQAndA(filter: {Page: {eq: "covid"}} sort: {order: ASC, fields: Index}) {
      edges {
        node {
          Question
          Answer
        }
      }
    }
  }
`

